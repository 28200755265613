.sidebar {
	width: 255px;
	height: 100%;
	overflow-y: scroll;
	position: fixed;
	background-color: var(--color-pri);

	.sidebar__setting {
		display: flex;
		text-align: start;
		padding: 0px 0px 0px 33px;
		// justify-content: space-between;
		// margin-left: -7px;
		align-items: center;

		a {
			margin-left: 33px;
		}

		img {
			height: 25px;
			// margin-right: 50px;
			width: 25px;
		}
	}

	&__menu {
		.sidebar__tab {
			width: 100%;
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: var(--hover-1);
			}

			&-wrapper {
				display: flex;
				cursor: pointer;

				.sidebar__img-container {
					width: 92px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.sidebar__tab-title {
					flex: 1;
					height: 56px;
					line-height: 56px;
					color: white;
					font-size: 16px;
					font-weight: 400;
				}
			}

			&-extend {
				display: flex;
				align-items: center;
				padding: 0 10px;

				i {
					transition: 0.3s ease-in-out;
					font-size: 20px;
					color: #fff;
				}
			}

			&-wrapperChild {
				height: 0;
				overflow: hidden;
			}

			&.active {
				.sidebar__tab-wrapperChild {
					height: auto;
				}

				.sidebar__tab-extend {
					i {
						transform: rotate(180deg);
					}
				}
			}

			&-child {
				&--title {
					width: 100%;
					height: 56px;
					line-height: 56px;
					color: black;
					font-weight: 400;
					font-size: 16px;
					padding-left: 72px;
					transition: 0.3s ease-in-out;
					background-color: var(--hover-2);

					//   &:hover {
					//     background-color: var(--hover-2);
					//   }
				}
			}
		}
	}

	&__setting {
		width: 100%;
		height: 56px;
		// line-height: 56px;
		font-size: 16px;
		// padding-left: 72px;
		// margin-top: 40px;

		a {
			color: white;
		}
	}
}