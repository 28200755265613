.notFound {
  width: 100%;
  background-color: var(--blue-login);
  height: 100vh;

  &__header {
    width: 100%;
    height: 55px;
    border: 1px solid #d9d9d9;

    &-wrapper {
      width: min(100%, 600px);
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      button {
        border: none;
        background-color: inherit;
        cursor: pointer;
        img {
          filter: brightness(0) saturate(100%) invert(20%) sepia(97%)
            saturate(2326%) hue-rotate(176deg) brightness(91%) contrast(101%);
        }
      }
    }
  }

  &__body {
    width: 100%;
    height: 300px;
    margin-top: 31px;

    &-wrapper {
      width: min(100%, 600px);
      height: 100%;
      margin: 0 auto;
      background-color: #f7f7f7;
      border: 1px solid #dcdcdc;
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: var(--text-color);
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 54px;
        text-align: center;
      }

      img {
        max-width: 120px;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin: 0;
        padding: 0;
        color: #767676;
      }
    }
  }
}
