@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
:root {
  --color-pri: #006889;
  --hover-1: rgba(255, 255, 255, 0.7);
  --hover-2: rgb(179, 210, 220);
  --background: #f7f8fc;
  --text-color: rgba(0, 104, 137, 0.5);
  --grey: rgba(239, 240, 246, 0.82);
  --blue-login:#E1E9F6;
}
