.header {
	height: 120px;
	width: 100%;
	padding: 17px 39px 60px 27px;
	display: flex;
	justify-content: space-between;

	&__link {
		display: flex;
		height: 40px;
		align-items: center;

		img {
			margin: 0 12px;
		}

		a {
			color: black;
			font-weight: 400;
			font-size: 16px;
		}
	}

	&__info {
		display: flex;

		.header__search {
			position: relative;
			width: 266px;
			height: 40px;
			background: #0068890d;
			border-radius: 8px;

			img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 22px;
			}

			input {
				width: 100%;
				height: 100%;
				border: none;
				border-radius: 8px;
				background: #0068890d;
				padding-left: 52px;
				color: var(--text-color);
				font-weight: 500;
				font-size: 14px;
				outline: none;

				&::placeholder {
					color: var(--text-color);
					font-weight: 500;
					font-size: 14px;
				}
			}
		}

		.header__profile {
			display: flex;
			align-items: center;
			height: 40px;

			.header__username {
				padding: 0 20px;
				font-weight: 600;
				font-size: 14px;
			}

			.header__avatar {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				transition: 0.3s ease-in-out;
				cursor: pointer;
				&:hover {
					transform: scale(3);
					transform-origin: 50% 0;
				}

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					border: 1px solid #999;
				}
			}
		}
	}
}
