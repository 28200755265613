.login-admin {
  width: 100%;
  height: 720px;
  display: flex;
  .login-banner {
    background-color: var(--blue-login);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-image: url("../../assets/images/login/banner-login.png");
    // background-repeat: no-repeat;
  }
  .login-form {
    // background-color: red;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form-detail {
      width: 380px;
      height: 580px;
      border-radius: 8px;
      border: 1px solid #dfe0eb;
      display: flex;
      padding: 100px 32px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .back-to-login {
        color: #2869ff;
        font-weight: 600;
        font-size: 14px;
      }
      .login-form-forget {
        position: relative;
        a {
          position: absolute;
          top: 0px;
          color: #3751ff;
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          right: 0px;
        }
      }
      .submit{
        cursor: pointer;
      }
      input {
        height: 43px;
        padding-left: 10px;
        width: 312px;
        background: #fcfdfe;
        border: 1px solid #f0f1f7;
        border-radius: 8px;
      }
      button {
        width: 312px;
        height: 48px;
        border: none;
        border-radius: 8px;
        color: white;
        font-weight: 600;
        font-size: 14px;
        background: #3751ff;
      }
      label {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #9fa2b4;
      }
      .smiletech-admin {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
      }
      .please-input-form {
        font-family: "Mulish";
        font-style: normal;
        // font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #9fa2b4;
      }
    }
  }
}
